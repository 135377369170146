










































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import {
  getNewsList,
  getSurplusList,
  uploadImg,
  addNews,
  editNews,
  deleteNewsById,
  getNewsTypeFullList
} from '@/serve/news/news';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
import { quillEditor } from 'vue-quill-editor'; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
interface FormData {
  size?: number;
  current?: number;
  diseaseName?: string;
  [propName: string]: any;
}
interface PersonList {
  [propName: string]: any;
}
interface AddFormData {
  title: string;
  editor: string;
  mainImage: string;
  contentText: string;
  categoryId: string;
  id?: string | number | null;
}
@Component({
  name: 'SchemeManage',
  components: { quillEditor },
})
export default class SchemeManage extends Vue {
  public fileList = [
    {
      name: '',
    },
  ];
  public reportMedical = [];
  public falseReport = [];
  public personMedical = [];
  public isSelect = false;
  public selectDisable = false;
  public selectMedical = '';
  public toolbar = [
    ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], //引用，代码块
    [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], //列表
    [{ script: 'sub' }, { script: 'super' }], // 上下标
    [{ indent: '-1' }, { indent: '+1' }], // 缩进
    [{ direction: 'rtl' }], // 文本方向
    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
    [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
    [{ font: [] }], //字体
    [{ align: [] }], //对齐方式
    ['clean'], //清除字体样式
    ['image', 'video'], //上传图片、上传视频
  ];
  public rules = {
    title: [{ required: true, message: '请填写文章标题', trigger: 'blur' }],
    editor: [{ required: true, message: '请填写文章作者', trigger: 'blur' },{ min: 1, max: 10, message: "作者长度在1到10个字符", trigger: "change" }],
    mainImage: [{ required: true, message: '请上传封面', trigger: 'blur' }],
    categoryId: [{ required: true, message: '请选择文章类别', trigger: 'blur' }],
  };
  public editorOption = {
    placeholder: '请在这里输入',
    modules: {
      toolbar: {
        container: this.toolbar,
        handlers: {
          image: (value: any) => {
            if (value) {
              (document.querySelector(
                '.avatar-uploader input'
              ) as HTMLElement).click();
            }
          },
        },
      },
    },
  };
  public currentPage4 = 1;
  public dialogFormVisible = false;
  public formLabelWidth = '120px';
  public str = '';
  public type = '添加';
  public form = {
    title: '',
    editor: '',
    mainImage:'',
    current: 1,
    size: 10,
    categoryId:'',
    tenantId: '',
  };
  public imgUrls = '';
  public detailContent = {};
  public tableData = [];
  public formData: AddFormData = {
    title: '',
    editor: '',
    mainImage:'',
    contentText: '',
    categoryId: '',
  };
  public file!: File;
  public imgUrl = '';
 
  public isAdmin = sessionStorage.getItem('isAdmin');
  public tenantName = '';
  public restaurants = [];
  public created() {
    this.getDiseaseList();
    // this.getSurplus();
    this.getCategoryList()
  }
  public mounted() {
    this.getTenantData();
  }
  public categoryList: any = []
  // 获取文章类别
  getCategoryList(){
    const params = {
      current: 1,
      size:999
    }
    getNewsTypeFullList(params).then(res=>{
      this.categoryList = res.data.records;
      console.log("输出文章类型")
      console.log(res.data.records)
    })
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  public async querySearch(queryString: any, cb: any) {
    const restaurants = this.restaurants;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  // public file!: string;
  //赋值给表单img字段
  public async handleAvatarSuccess(res: { file: File }) {
    // console.log("res照片 :>> ", res);
    const imgs = new FormData();
    imgs.append("file", res.file);
    const file = await uploadImg(imgs);
    // console.log("11111111111111",file)
    // this.file = file.data;
    this.formData.mainImage =String(file.data);
    // this.formData.jkglTenantDetail.tenantDetailImage = file.data;
  }

 
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.currentPage4 = 1;
    this.getDiseaseList();
  }
  // 进入页面获取数据
  public async getDiseaseList() {
    
    this.$changNull(this.form);
    // console.log("111111111",this.form)
    const res = await getNewsList(this.form);
    // console.log("全部信息",res)
    this.tableData = res.data;
  }
  // 查询剩余未健康测评的体检数据
  public async getSurplus() {
    const trueres = await getSurplusList({ type: 'true' });
    this.reportMedical = trueres.data;
    const falseres = await getSurplusList({ type: 'false' });
    this.falseReport = falseres.data;
    // const falseres = await getSurplusList({type:'true'});
  }
  // 点击添加方案按钮
  public addSchema() {
    this.type = '添加';
    this.selectDisable = false;
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs['formData'] as ElForm).resetFields();
      this.formData.title = '';
      this.formData.mainImage = '';
    });
    // this.formData.title = '';
    // this.isSelect = false;
    // this.personMedical = [];
    // this.selectMedical = '';
  }
  // 按取消按钮 清除页面数据
  public hanlderCancel() {
    
    // console.log('this.formData 11111111111', this.formData);
    (this.$refs['formData'] as ElForm).resetFields();
    // console.log('this.formData       2', this.formData);
    this.dialogFormVisible = false;
    this.formData.contentText = '';
    this.formData.categoryId = '';
    this.selectMedical = '';
    this.personMedical = [];
    this.isSelect = false;
  }
  // 点击编辑按钮显示
  public async editRow(id: number, row: any): Promise<void> {
    console.log(row);
    this.formData = {...row}
    this.type = '编辑';
    this.selectDisable = true;
    this.dialogFormVisible = true;
    // const res = await getDetailProgram(id);
    // if (res.code === 200) {
    //   this.dialogFormVisible = true;
    //   const resData = res.data;
    //   this.formData.id = id;
    //   this.formData.title = (resData as PersonList).title;
    //   this.formData.contentText = (resData as PersonList).contentText;
    //   this.formData.editor = (resData as PersonList).editor;
    //   this.formData.mainImage = (resData as PersonList).mainImage;
    //   this.formData.categoryId = (resData as PersonList).categoryId;
      
    //   const list = await getSurplusList({
    //     type: 'true',
    //     id: (resData as PersonList).reportRegistId,
    //   });
      // console.log('list', list.data);

      // const newArr: PersonList = [];
      // for (const key in list.data) {
      //   newArr.push(list.data[key]);
      // }
      // this.selectMedical = list.data[0].reportName;
      // if (this.selectMedical === undefined) {
      //   this.isSelect = false;
      // } else {
      //   this.isSelect = true;
      // }
      // (this.personMedical as PersonList) = newArr;
      // for (const key in this.reportMedical) {
      //   if (id === (this.reportMedical[key] as PersonList).id) {
      //     const newArr: PersonList = [];
      //     newArr.push(this.reportMedical[key]);
      //     (this.personMedical as PersonList) = newArr;
      //     this.selectMedical = (this.personMedical[0] as PersonList).reportName;
      //     if (this.selectMedical === undefined) {
      //       this.isSelect = false;
      //     } else {
      //       this.isSelect = true;
      //     }
      //   }
      // }
    // }
  }
  public onSubmit(): void {
    this.getDiseaseList();
  }
  // 删除操作
  public async deleteRow(id: number, item: any): Promise<void> {
    // console.log("删除id",id);

    (this as any).$messageBox
      .confirm(`您确定是否删除标题为${item.title}的这一条数据`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const res = await deleteNewsById(id);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getDiseaseList();
        }
      });
  }
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getDiseaseList();
  }
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getDiseaseList();
  }
  // 改变体检报告的值发生的方法
  // public changeContent(e: any) {
  //   this.isSelect = true;
  //   const arr = [];
  //   arr.push(e);
  //   // 转化为数组格式
  //   (this.personMedical as PersonList) = arr;
  //   // select绑定值
  //   this.selectMedical = (this.personMedical[0] as PersonList).reportName;
  //   // 接口需要的值
    
  //   if (this.selectMedical === undefined) {
  //     this.isSelect = false;
  //   }
  // }
  // 上传之前的操作
  public beforeAvatarUpload(file: File) {
    const isJPG = file.type === 'image/jpeg';
    // console.log('file.type :>> ', file.type);
    const isPNG = file.type === 'image/png';
    const isPG = isJPG || isPNG;
    const isLt2M = file.size / 1024 / 1024 < 1;
    // console.log('isPG :>> ', isPG);
    // console.log('isLt2M :>> ', isLt2M);
    if (!isPG) {
      this.$message.error('上传头像图片只能是 JPG/PNG格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传头像图片大小不能超过 2MB!');
    }
    return isPG && isLt2M;
  }
  // 自定义上传
  public async uploadFunc(res: { file: File }) {
    this.file = res.file;
    // console.log('this.file', this.file);
    this.imgUrl = URL.createObjectURL(res.file);
    // console.log('this.imgUrl', this.imgUrl);
    const imgs = new FormData();
    imgs.append('file', this.file);
    const Img = await uploadImg(imgs);
    const quill = (this.$refs.myQuillEditor as any).quill;
    if (Img.code === 200 && Img.data !== null) {
      const length = quill.getSelection().index;
      quill.insertEmbed(length, 'image', Img.data);
      quill.setSelection(length + 1);
    } else {
      this.$message.error('图片插入失败');
    }
  }
  // 提交表单
  public async handlerSubmit(formName: string) {
    (this.$refs[formName] as ElForm).validate(async (valid) => {
      if (valid) {
        let res: any = {}
        if (this.type === '添加') {
          this.formData.id = 0;
          res = await addNews(this.formData);
        }else{
          // 修改
          res = await editNews(this.formData);
        }
        // console.log("666",this.formData)
        // const res = await addNews(this.formData);
        if (res.code === 200) {
          this.dialogFormVisible = false;
          (this.$refs[formName] as ElForm).resetFields();
          this.formData.contentText = '';
          this.getDiseaseList();
          // this.getSurplus();
        }
      } else {
        // console.log('error submit!!');
        return false;
      }
    });
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
}
